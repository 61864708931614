const DIRECT_SELLING_RECURRENCE_TYPE = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
};

const DIRECT_SELLING_RECURRENCE_TYPES = Object.values(
  DIRECT_SELLING_RECURRENCE_TYPE,
);

export { DIRECT_SELLING_RECURRENCE_TYPES, DIRECT_SELLING_RECURRENCE_TYPE };
