import PropTypes from 'prop-types';

const propType = {
  commissionModelTypes: PropTypes.arrayOf(PropTypes.string),
  recurrenceTypes: PropTypes.arrayOf(PropTypes.string),
  marketTriggers: PropTypes.arrayOf(PropTypes.string),
  directSellingDefault: PropTypes.shape({
    recurrence: PropTypes.string,
    marketTriggers: PropTypes.shape({
      oldStatus: PropTypes.string,
      newStatus: PropTypes.string,
    }),
    binaryPlaceUserSchedule: PropTypes.string,
    binaryPlaceUserBehaviours: PropTypes.arrayOf(PropTypes.string),
    defaultPlaceUserBehaviour: PropTypes.string,
    schedule: PropTypes.shape({
      recurrenceStartMonth: PropTypes.number,
      recurrenceStartDayOfMonth: PropTypes.number,
      recurrenceStartDayOfWeek: PropTypes.number,
      recurrenceTriggerStartHour: PropTypes.number,
      timezone: PropTypes.string,
    }),
  }),
};

const defaultProp = {
  commissionModelTypes: [],
  recurrenceTypes: [],
  marketTriggers: [],
  directSellingDefault: {
    recurrence: 'monthly',
    marketTriggers: {
      oldStatus: 'pending_payment',
      newStatus: 'pending',
    },
    binaryPlaceUserSchedule: 'before_daily',
    binaryPlaceUserBehaviours: [],
    defaultPlaceUserBehaviour: 'manual',
    schedule: {
      recurrenceStartMonth: 1,
      recurrenceStartDayOfMonth: 1,
      recurrenceStartDayOfWeek: 1,
      recurrenceTriggerStartHour: 0,
      timezone: 'UTC',
    },
  },
};

export default { propType, defaultProp };
